.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 2147483645;
    box-sizing: border-box;
    width: 100%;

    background-color: #F1F6F4;
}

.cookie-consent-banner__inner {
    max-width: 960px;
    margin: 0 auto;
    padding: 32px 0;
}

.cookie-consent-banner__copy {
    margin-bottom: 16px;
}



.cookie-consent-banner__header {
    margin-bottom: 8px;

    font-family: "CeraPRO-Bold", sans-serif, arial;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.cookie-consent-banner__description {
    font-family: "CeraPRO-Regular", sans-serif, arial;
    font-weight: normal;
    color: #838F93;
    font-size: 16px;
    line-height: 24px;
}

.cookie-consent-banner__cta {
    box-sizing: border-box;
    display: inline-block;
    min-width: 164px;
    padding: 11px 13px;

    border-radius: 2px;

    background-color: #2CE080;

    color: #FFF;
    text-decoration: none;
    text-align: center;
    font-family: "CeraPRO-Regular", sans-serif, arial;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
}

.cookie-consent-banner__cta--secondary {
    padding: 9px 13px;

    border: 2px solid #3A4649;

    background-color: transparent;

    color: #2CE080;
}

.cookie-consent-banner__cta:hover {
    background-color: #20BA68;
}

.cookie-consent-banner__cta--secondary:hover {
    border-color: #838F93;

    background-color: transparent;

    color: #22C870;
}

.cookie-consent-banner__cta:last-child {
    margin-left: 16px;
}



@media (max-width: 1100px) {
.cookie-consent-banner__inner {
    max-width: 860px;

}
}

@media (max-width: 900px) {
    .cookie-consent-banner__inner {
        max-width: 760px;
  
    }
    }

    @media (max-width: 850px) {
        .cookie-consent-banner__inner {
            max-width: 660px;
        
        }
        }

        @media (max-width: 700px) {
            .cookie-consent-banner__inner {
                max-width: 560px;
        
            }
            }

            @media (max-width: 600px) {
                .cookie-consent-banner__inner {
                    max-width: 430px;
            
                }
                }
                @media (max-width: 450px) {
                    .cookie-consent-banner__inner {
                        max-width: 330px;
                     
                    }
                    }